import React from 'react';
import styled from 'styled-components';
import suppliersBg from '../assets/images/blues-sub-header-suppliers.jpg';
import cowsMilkSupplier from '../assets/images/supplier-02.jpg';
import sheepsMilkSupplier from '../assets/images/supplier-03.jpg';
import goatsMilkSupplier from '../assets/images/supplier-goat.jpg';
import SEO from '../components/SEO';

const Supplier = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;
  padding-bottom: 10px;
  border-bottom: solid 1px var(--blue);
  margin-bottom: 30px;
  color: #333;

  @media (max-width: 680px) {
    display: block;
    padding: 15px;
  }

  h4 {
    font-size: 2rem;
    color: var(--blue);
  }
  div {
    font-size: 1.6rem;
    line-height: 150%;
  }
`;

const SuppliersHeader = styled.div`
  background: url(${suppliersBg});
  background-size: cover;
  background-position: top center;
  min-height: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  @media (min-width: 1500px) {
    background-position: top -120px center;
  }
  @media (max-width: 680px) {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h3 {
    font-family: BrandFont;
    font-size: 3.5rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
    color: white;
  }
`;

const SuppliersBody = styled.div`
  text-align: left;
`;

export default function SuppliersPage() {
  return (
    <>
      <SEO title="Our Suppliers" />
      <SuppliersHeader>
        <div className="content-mw-1100">
          <h3>Our Suppliers</h3>
        </div>
      </SuppliersHeader>
      <SuppliersBody className="content-mw-1100">
        <Supplier>
          <div>
            <h4>Richard and Bernie Donovan, Bohercarron</h4>
            <p>
              Our Cows milk is supplied by Richard and Bernie Donovan in
              Bohercarron, Emly, Co Tipperary. They operate a free ranging grass
              grazing system as the primary food source for their Holstein
              Friesian and Jersey breed of Dairy Cows.Holstein Friesians cows
              originated from the Dutch provinces of North Holland and Friesian,
              and Schleswig - Holstein in Germany. Jersey cows are one of the 3
              Channel Island Breeds.
            </p>
          </div>
          <img src={cowsMilkSupplier} alt="" />
        </Supplier>
        <Supplier>
          <div>
            <h4>Barry and Lorraine Cahalan, Terryglass</h4>
            <p>
              Our Sheep’s milk is supplied by Barry and Lorraine Cahalan in
              Terryglass ,County Tipperary.They operate a free ranging grass
              grazing system as the primary food source for their Friesian breed
              of Dairy Sheep. The Friesian sheep originated from East Frisia in
              Northern Germany. They are also cheese makers who produce the
              acclaimed farm house cheese <em>Cais Na Tire</em> which is also
              available from Blues Creamery.
            </p>
          </div>
          <img src={sheepsMilkSupplier} alt="" />
        </Supplier>
        <Supplier>
          <div>
            <h4>Michael and Anne Mc Gelligit, Cahir</h4>
            <p>
              Our Goats milk is supplied by Michael and Anne Mc Gelligit whose
              family farm is based near Cahir County Tipperary. They operate a
              zero grass grazing system as the primary food source for their
              Saanen breed of Dairy Goats. The Saanen is a Swiss breed of goat
              and takes its name from Saanental in the Canton of Bern.
            </p>
          </div>
          <img src={goatsMilkSupplier} alt="" />
        </Supplier>
      </SuppliersBody>
    </>
  );
}
